
    p {
        margin: 0;
    }

    .p_legal_content {
        h1 {
            font-weight: 600;
            margin-top: 0.5rem;
            margin-bottom: 5rem;
            line-height: 100%;
        }

        h2 {
            font-size: 2rem;
            margin-top: 3.5rem;
            margin-bottom: 1.5rem;
            line-height: 100%;
            font-weight: 600
        }

        h3 {
            font-size: 1.1rem;
            margin-top: 2.2rem;
            margin-bottom: 1.5rem;
            line-height: 100%;
            font-weight: 600
        }

        h3 {
            font-size: 1rem;
            margin-top: 2rem;
            margin-bottom: 1.2rem;
            line-height: 100%;
            font-weight: 600
        }
    }

    .p_legal_content_mobile {
        h1 {
            font-weight: 600;
            margin-top: 0rem;
            margin-bottom: 2.5rem;
            line-height: 100%;
            font-size: 2.5rem;
        }

        h2 {
            font-size: 1.5rem;
            margin-top: 2.5rem;
            margin-bottom: 1rem;
            line-height: 100%;
            font-weight: 600
        }

        h3 {
            font-size: 1.1rem;
            margin-top: 2.2rem;
            margin-bottom: 1rem;
            line-height: 100%;
            font-weight: 600
        }

        h3 {
            font-size: 1rem;
            margin-top: 2rem;
            margin-bottom: 0.75rem;
            line-height: 100%;
            font-weight: 600
        }
    }
