
    .p-hero-cover {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .p-hero-cover::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        background: rgb(5,6,26);
        background: linear-gradient(180deg, rgba(5,6,26,.6) 0%, rgba(5,6,26,.5) 75%, rgba(5,6,26,.3) 100%);
        z-index: 3;
        top: 0;
        left: 0;
    }

    .p-hero-content {
        max-width: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .p-hero-icons {
        z-index: 4;
        margin-top: 3.5rem;
        min-width: 50vw;
    }

    .p-hero-icons-element {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 150px
    }

    .p-hero-icons-element-background {
        background: rgba(5, 6, 26, .3);
        border-radius: 50%;
        transition: .3s background;
    }

    .p-hero-icons-element:hover .p-hero-icons-element-background {
        background: rgba(5, 6, 26, .6)
    }
