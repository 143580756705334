
    .p-card {
        transition: .3s all;
    }

    .p-card:hover {
        box-shadow: 0px 0px 42px -4px var(--q-primary);
    }

    .transparent_image {
        opacity: 0.3;
    }
