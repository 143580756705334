
    .hero_video {
        width: 100%;
        height: auto
    }

    .hero_video2 {
        width: auto;
        height: 100%
    }

    .video_overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
    }

    .video_overlay::after {
        position: absolute;
        top: 0;
        content: '';
        left: 0;
        height: 100%;
        width: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0.1) 100%);
    }

    .video_overlay_sm::after {
        background: linear-gradient(90deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0.3) 100%);
    }

    .title_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 900px;
        z-index: 4;
    }

    .headline {
        color: rgba(255, 255, 255, 1);
        margin: 0;
        font-size: 4rem;
        line-height: 4rem;
        font-weight: 600;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, .3)
    }

    .subline {
        color: rgba(255, 255, 255, .8);
        margin: 0;
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 500;
        margin-top: 1rem;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, .3)
    }

    .headline_sm {
        font-size: 2rem;
        line-height: 2rem;
    }

    .subline_sm {
        font-size: 1rem;
        line-height: 1rem;
    }
