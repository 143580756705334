// app global css in SCSS form
a {
    color: var(--q-link);
    font-weight: 500;
    text-decoration: underline
}

::selection {
    color: var(--q-accent2);
    background: var(--q-tertiary);
}

iframe {
    border-radius: 5px
}

p {
    line-height: 175%
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--q-primary) var(--q-accent2);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: var(--q-accent2)
}

*::-webkit-scrollbar-thumb {
    background-color: var(--q-primary);
    border-radius: 20px;
    border: 3px solid var(--q-accent2)
}

h6 {
    font-size: 1.2em;
    line-height: 130%
}
