
    .indexIndicator {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate3d(-25%, -50%, 0);
        font-size: 4em;
        font-weight: 800;
    }

    .indexIndicatorDark {
        text-shadow: 1px 1px 4px rgba(0,0,0,0.8)
    }
