// app global css in SCSS form

.text-link {
	color: var(--q-link)
}

.bg-link {
	color: var(--q-link)
}

.text-text {
	color: var(--q-text)
}

.bg-text {
	background: var(--q-text)
}

.text-text2 {
	color: var(--q-text2)
}

.bg-text2 {
	background: var(--q-text2)
}

.text-text3 {
	color: var(--q-text3)
}

.bg-text3 {
	background: var(--q-text3)
}

.text-tertiary {
	color: var(--q-tertiary)
}

.bg-tertiary {
	background: var(--q-tertiary)
}

.text-accent {
	color: var(--q-accent2)
}

.bg-accent {
	background: var(--q-accent)
}

.text-accent2 {
	color: var(--q-accent2)
}

.bg-accent2 {
	background: var(--q-accent2)
}

.text-accent3 {
	color: var(--q-accent3)
}

.bg-accent3 {
	background: var(--q-accent3)
}

.text-pagisto {
	color: var(--q-pagisto)
}

.bg-pagisto {
	background: var(--q-pagisto)
}

.text-pagistoni_dark {
	color: var(--q-pagistoni_dark)
}

.bg-pagistoni_dark {
	background: var(--q-pagistoni_dark)
}

.text-pagistoni_light {
	color: var(--q-pagistoni_light)
}

.bg-pagistoni_light {
	background: var(--q-pagistoni_light)
}

.p_code {
  font-family: 'Red Hat Mono', monospace;
}
