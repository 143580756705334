
    .p_hover {
        transform: scale(0);
        opacity: 0;
        transition: all .3s
    }

    .p_hover_parent:hover .p_hover {
        opacity: 1;
        transform: scale(1);
    }

    .p_headline {
        border-bottom: 2px solid var(--q-tertiary);
        position: absolute;
        top: 0;
    }

    .p_headline_even {
        left: 0;
        padding-left: 15%;
    }

    .p_headline_odd {
        right: 0;
        padding-right: 15%;
    }
